import "./App.css";

import React, { useEffect, useState } from "react";

import Diagonal from "./components/layout/Diagonal";
import Footer from "./components/layout/Footer";
import LandingSection from "./components/LandingSection";
import ParticlesCanvas from "./components/layout/ParticlesCanvas";
import ProjectSection from "./components/project/ProjectSection";

// import ArticleSection from "./components/article/ArticleSection";

function App() {
  const [skewAngle, setSkewAngle] = useState("-7"); // Default angle

  useEffect(() => {
    const calculateSkewAngle = () => {
      // Example calculation (modify as needed based on actual layout requirements)
      const windowHeight = window.innerHeight;
      const sideA = windowHeight * 0.2; // 20vh = 20% of window height
      const sideB = window.innerWidth; // 100% of window width
      const angleRadians = Math.atan(sideA / sideB);
      const angleDegrees = angleRadians * (180 / Math.PI);

      setSkewAngle(angleDegrees.toFixed(2)); // Update state
    };

    calculateSkewAngle(); // Initial calculation
    window.addEventListener("resize", calculateSkewAngle); // Recalculate on window resize

    return () => window.removeEventListener("resize", calculateSkewAngle); // Cleanup on component unmount
  }, []);

  return (
    <div className="App">
      <Diagonal
        upperColor="rgba(4, 45, 98, 0.7)"
        lowerColor="rgba(0, 0, 0, 0)"
        height="20vh"
        text=""
      />

      <LandingSection />

      <ProjectSection skewAngle={skewAngle} />

      {/* <ArticleSection skewAngle={skewAngle} /> */}

      {/* <Diagonal
        upperColor="rgba(1, 16, 36, 0.7)"
        lowerColor="rgba(1, 16, 36, 0.7)"
        height="20vh"
        text=""
      /> */}

      <ParticlesCanvas />
      <Footer />
    </div>
  );
}

export default App;
