import Diagonal from "../layout/Diagonal";
import Project from "./Project";
import React from "react";
import exoticMenu from "../../assets/exotic_menu.webp";
import goBlockchain from "../../assets/go_blockchain.webp";
import gorillaLabs from "../../assets/gorilla_labs.webp";
import reactHooks from "../../assets/react_hooks.webp";
import starWars from "../../assets/star_wars.webp";
import styled from "styled-components";
import suitsuvares from "../../assets/suitsuvares.webp";
import textDiff from "../../assets/text_diff.webp";

const projects: Project[] = [
  {
    name: "Gorilla Labs",
    description:
      "This project is an NFT marketplace designed to broaden and demonstrate my technical skills. It is currently in development, so appearances may vary over time. ",
    demo: "https://gorilla-labs.com",
    image: gorillaLabs,
    sourceCode: "https://github.com/elarsaks/gorilla-labs",
    technologies: "React, TypeScript, Node, PostgreSQL, AWS",
  },
  {
    name: "Star Wars",
    description:
      "This is a coding assignment created for a job application. Its goal is to demonstrate UI design implementation, API integration, error handling, code quality, testing, and application development using ReactJS and TypeScript. It is solely a front-end application that performs basic API calls, pagination, and mock authentication.",
    demo: "https://elarsaks.github.io/SWAPI",
    image: starWars,
    sourceCode: "https://github.com/elarsaks/SWAPI",
    technologies: "React, TypeScript, Github Actions",
  },
  {
    name: "REACT HOOKS",
    description:
      "While developing Go Blockchain, I realised that my knowledge of React's lifecycle methods wasn't translating effectively to the use of React Hooks. Therefore, I decided to create this small project as a learning exercise.",
    demo: "https://elarsaks.github.io/react-hooks",
    image: reactHooks,
    sourceCode: "https://github.com/elarsaks/react-hooks",
    technologies: "React",
  },
  {
    name: "Go blockchain",
    description:
      "A Docker-based blockchain application that I built to gain some exposure to Golang and React while learning about blockchain logic.",
    demo: "https://elarsaks.github.io/Go-blockchain/",
    image: goBlockchain,
    sourceCode: "https://github.com/elarsaks/Go-blockchain",
    technologies: "React, TypeScript, Golang, Docker, Azure, Blockchain",
  },
  {
    name: "Suitsuvares",
    description:
      "a template-based WordPress website for a small meat shop in Estonia.",
    demo: "https://suitsuvares.org/",
    image: suitsuvares,
    sourceCode: "",
    technologies: "WordPress",
  },
  {
    name: "Text － Diff",
    description:
      "This is a user-friendly tool designed to compare and highlight differences between two text inputs. It's inspired by the diff utility, which is traditionally used to show differences between two files.",
    demo: "https://elarsaks.github.io/text-diff/",
    image: textDiff,
    sourceCode: "https://github.com/elarsaks/text-diff",
    technologies: "Html, CSS, LCS algorithm",
  },
  {
    name: "Exotic Looking Menu",
    description:
      "Breaking away from conventional UI designs, the 'Exotic-looking-menu' offers a fresh, standout experience in menu interfaces.",
    demo: "https://elarsaks.github.io/exotic-menu/",
    image: exoticMenu,
    sourceCode: "https://github.com/elarsaks/exotic-menu",
    technologies: "React",
  },
];

const Container = styled.div`
  position: relative;
  margin-top: -15vh;
  height: 100%;
  width: 100%;
  background-color: rgba(242, 247, 255, 0);
  z-index: 99;
`;

interface ContainerContentProps {
  $backgroundColor: string;
}

const ContainerContent = styled.div<ContainerContentProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.$backgroundColor};
  z-index: 99;
`;

type ProjectSectionProps = {
  skewAngle: string;
};

const ProjectSection: React.FC<ProjectSectionProps> = ({ skewAngle }) => {
  const colors = [
    "rgba(255, 87, 34, 0.5)",
    "rgba(33, 150, 243, 0.5)",
    "rgba(156, 39, 176, 0.5)",
    "rgba(76, 175, 80, 0.5)",
    "rgba(255, 193, 7, 0.5)",
    "rgba(255, 20, 147, 0.5)",
    "rgba(0, 255, 127, 0.5)",
    "rgba(255, 99, 71, 0.5)",
    "rgba(173, 216, 230, 0.683)",
    "rgba(244, 164, 96, 0.074)",
    "rgba(218, 117, 131, 0.683)",
    "rgba(192, 135, 233, 0.074)",
    "rgba(151, 37, 159, 0.965)",
    "rgba(56, 201, 187, 0.787)",
    "rgba(146, 151, 247, 0.420)",
    "rgba(7, 140, 234, 0.529)",
    "rgba(69, 4, 50, 0.307)",
    "rgba(47, 193, 70, 0.974)",
    "rgba(189, 148, 59, 0.378)",
    "rgba(0, 0, 0, 0)",
  ];

  return (
    <Container>
      <Diagonal
        upperColor={colors[colors.length - 1]}
        lowerColor={colors[0]}
        height="20vh"
        text="PROJECTS"
      />

      {projects.map((project, index) => {
        const isLastProject = index === projects.length - 1;

        return (
          <React.Fragment key={project.name}>
            <ContainerContent $backgroundColor={colors[index]}>
              <Project project={project} skewAngle={skewAngle} />
            </ContainerContent>

            <Diagonal
              upperColor={colors[index]}
              lowerColor={
                isLastProject ? "rgba(0, 0, 0, 0.7)" : colors[index + 1]
              }
              height="20vh"
              text=""
            />
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default ProjectSection;
