import {
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import centeredImage from "../assets/profile.webp";
import pdfFile from "../assets/Elar Saks (CV).pdf";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;

  z-index: 99;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  height: 30vh;
  border: 5px solid #ffffff;
`;

const SocialMediaContainer = styled.div`
  margin-top: 2vh;
  border: 5px solid rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  color: white;
  transition: border-color 0.3s;
  margin-bottom: 15vh;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const SocialMediaIconRow = styled.div`
  display: flex;
  gap: 40px;
`;

const SocialMediaIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  transition: color 0.3s;
  cursor: pointer;
  color: #ffffff !important;

  &:hover {
    color: #47eaff !important;
  }
`;

const Header = styled.h1`
  font-size: 40px;
  margin-bottom: 0;
  margin-top: 0;
`;

const DownloadCVLink = styled.a`
  font-size: 20px;
  text-decoration: none;
  color: white;
  transition: color 0.3s;

  &:hover {
    color: #47eaff;
  }
`;

const openPdfInNewWindow = () => {
  window.open(pdfFile, "_blank");
};

const socialMediaLinks = {
  github: "https://github.com/elarsaks",
  linkedin: "https://www.linkedin.com/in/elarsaks/",
  facebook: "https://www.facebook.com/elar.saks/",
  instagram: "https://www.instagram.com/elarsaks/",
};

const ProfileSection: React.FC = () => {
  return (
    <Container>
      <ProfileImage src={centeredImage} alt="Centered and rounded image" />

      <SocialMediaContainer>
        <Header>Elar Saks</Header>
        <SocialMediaIconRow>
          <a
            href={socialMediaLinks.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialMediaIcon icon={faGithub} />
          </a>
          <a
            href={socialMediaLinks.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialMediaIcon icon={faLinkedin} />
          </a>
          <a
            href={socialMediaLinks.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialMediaIcon icon={faFacebook} />
          </a>
          <a
            href={socialMediaLinks.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialMediaIcon icon={faInstagram} />
          </a>
        </SocialMediaIconRow>

        <DownloadCVLink href="#" onClick={openPdfInNewWindow}>
          Download CV
        </DownloadCVLink>
      </SocialMediaContainer>
    </Container>
  );
};

export default ProfileSection;
